import React from "react"
import styled, { css } from "styled-components"
import { useAnimatedBorder } from "../helpers"

const CenterBorderedHeaderWrapper = styled.div`
  position: relative;
  margin: auto;
  text-align: center;
  padding: 30px 0;
  font-size: inherit;
  border: 1px solid ${(props) => props.theme.secondary};
  border-left: none;
  border-right: none;
  width: 450px;
  ${(props) =>
    props.size === "small" &&
    css`
      width: 250px;
    `}
  @media (max-width: 768px) {
    width: 150px;
  }
`

const HeaderBorder = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  left: 0;
  ${(props) =>
    props.side === "right" &&
    css`
      left: auto;
      right: 0;
    `}
  ::before,
  ::after {
    content: "";
    position: absolute;
    left: 0;
    border-left: 1px solid ${(props) => props.theme.secondary};
    ${(props) =>
      props.side === "right" &&
      css`
        left: auto;
        right: 0;
        border-left: none;
        border-right: 1px solid ${(props) => props.theme.secondary};
      `}
    height: 20px;
    transition: transform 1s ease-out;
  }
  ::before {
    top: 0;
    transform: scaleY(calc(1 + var(--scale)));
    ${(props) =>
      props.side === "right" &&
      css`
        transform: scaleY(calc(1 - var(--scale)));
      `}
    transform-origin: top;
  }
  ::after {
    bottom: 0;
    transform: scaleY(calc(1 - var(--scale)));
    ${(props) =>
      props.side === "right" &&
      css`
        transform: scaleY(calc(1 + var(--scale)));
      `}
    transform-origin: bottom;
  }
`

const CenterBorderedHeader = ({ isH1, size, children }) => {
  const [boxRef] = useAnimatedBorder()
  return (
    <CenterBorderedHeaderWrapper size={size} ref={boxRef}>
      <HeaderBorder side="left" size={size}  />
      {isH1 ? (
        <h1
          style={{
            fontSize: "inherit",
            margin: "0 calc(-50vw + 50%)",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {children}
        </h1>
      ) : (
        <h2
          style={{
            fontSize: "inherit",
            margin: "0 calc(-50vw + 50%)",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {children}
        </h2>
      )}
      <HeaderBorder side="right" size={size}  />
    </CenterBorderedHeaderWrapper>
  )
}
export default CenterBorderedHeader
